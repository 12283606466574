import { FormControl, FormHelperText, TextareaAutosize, TextField, TextFieldProps, Typography } from '@mui/material'
import React from 'react'
import { InputCaption, InputLabel } from './InputLabel'
import { COLORS } from 'utils'

interface Props extends Omit<TextFieldProps, 'variant'> {
  iconstart?: React.JSX.Element
  width?: any
  caption?: string
  label?: string
  require?: boolean
  backgroundColor?: string
  textcolor?: string
  handleChange?: (value: string) => void
  err_msg?: string
}
export function MyTextArea({ textcolor, caption, err_msg, backgroundColor, disabled, label, width, require, iconstart, type, handleChange, ...others }: Props) {
  return (
    <FormControl fullWidth>
      {label && <InputLabel label={label} require={require} caption={caption} />}
      <TextareaAutosize
        {...others}
        onChange={(e) => {
          handleChange?.(e.target.value)
        }}
        disabled={disabled}
        style={{
          maxHeight: 400,
          padding: '4px 6px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '13px',
          resize: 'both',
          marginTop: '4px',
          outline: 'none',
          color: COLORS.DarkCharcoal,
          ...(others.style ?? {})
        }}
      />
      {err_msg && <FormHelperText error>{err_msg}</FormHelperText>}
    </FormControl>
  )
}
