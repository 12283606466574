import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { showToast } from 'components'
import { useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector as useReduxSelector } from 'react-redux'
import Api from 'services'
import { RootState } from 'store'
function useSelector<T>(selector: (state: RootState) => T, equalityFn = isEqual): T {
  return useReduxSelector<RootState, T>(selector, equalityFn)
}
function useAuth(): boolean {
  const { user } = useSelector((x) => x.profile)
  return user !== undefined
}

function useSyncPortal() {
  const [enableSync, setEnableSync] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const syncPortal = useCallback(() => {
    if (!enableSync) {
      return
    }
    showToast({ content: 'Đang đồng bộ thông tin từ portal', type: 'info', duration: 5000 })
    setEnableSync(false)
    setLoading(true)

    Api.portal
      .sync()
      .then(() => {
        showToast({ content: 'Đồng bộ từ Portal thành công', type: 'success' })
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
        setEnableSync(true)
      })
  }, [enableSync])

  return { syncPortal, loading, enableSync }
}
export type PlatformType = 'mobile' | 'tablet' | 'laptop' | 'desktop'
function useUI() {
  const getPlatform = (width: number): PlatformType => {
    if (width < 640) return 'mobile' // Small screens
    if (width < 1024) return 'tablet' // Medium screens
    if (width < 1280) return 'laptop' // Large screens
    return 'desktop' // Extra large screens
  }

  const [platform, setPlatform] = useState(getPlatform(window.innerWidth))

  useEffect(() => {
    const handleResize = () => setPlatform(getPlatform(window.innerWidth))

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const is_large_ui = platform === 'desktop' || platform === 'laptop'
  return { platform, screen_width: window.innerWidth, screen_height: window.innerHeight, is_large_ui }
}

export { useAuth, useSelector, useSyncPortal, useUI }
