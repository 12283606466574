import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const update = (id: string, data: ApiRequest.campaign.update) => instanceAxios.patch(`/campaigns/${id}`, data)

const show = async (id: string): Promise<any> => instanceAxios.get(`/campaigns/${id}`)

const integrate_survey = async (id: string, survey_id: string): Promise<any> => instanceAxios.patch(`/campaigns/${id}/integrate-survey/${survey_id}`)
const unintegrate_survey = async (id: string): Promise<any> => instanceAxios.patch(`/campaigns/${id}/unintegrate-survey`)

const get_survey_links = async (id: string): Promise<any> => instanceAxios.get(`/campaigns/${id}/get-survey-links`)
const guide_link = async (): Promise<any> => instanceAxios.get(`/campaigns/guide-link`)

const store_transfer = (id: string, data: ApiRequest.campaign.store_transfer): Promise<any> => instanceAxios.post(`/campaigns/${id}/transfer/stores`, data)

const employee_distribute = (id: string, data: ApiRequest.campaign.employee_distribute): Promise<any> =>
  instanceAxios.post(`/campaigns/${id}/distribute/employees`, data)
const store_distribute = (id: string, data: ApiRequest.campaign.store_distribute): Promise<any> =>
  instanceAxios.post(`/campaigns/${id}/distribute/stores`, data)
const employee_regain = (id: string, data: ApiRequest.campaign.employee_regain): Promise<any> => instanceAxios.post(`/campaigns/${id}/regain/employee`, data)
const store_regain = (id: string, data: ApiRequest.campaign.store_regain): Promise<any> => instanceAxios.post(`/campaigns/${id}/regain/store`, data)
export default {
  update,
  show,
  store_transfer,

  employee_distribute,
  store_distribute,
  employee_regain,
  store_regain,
  guide_link,
  integrate_survey,
  get_survey_links,
  unintegrate_survey
}
