import { showApiError } from 'components'
import { env } from 'config'
import { instanceAxios } from 'utils'

const upload = async (file: any) => {
  try {
    const form = new FormData()
    form.append('file', file)
    form.append('title', file.name)

    const res: any = await instanceAxios.post(`${env.api.customer_care}/attachment`, form, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    return res?.data?.data
  } catch (e) {
    showApiError(e)
    return null
  }
}

export default { upload }
