/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PaginationEntity, SurveyFeedbackEntity, SurveyStatisticState } from 'models'
import { ApiRequest } from 'services'

const initialState: SurveyStatisticState = {
  feedbacks: [],
  feedback_filter: {
    all: true,
    hidden: false
  },

  feedback_index: 0,
  hide_non_answer_questions: true,
  exporting_statistic: false
}
const slice = createSlice({
  initialState,
  name: 'survey_statistic',
  reducers: {
    setFeedbackIndex: (state, { payload }: PayloadAction<number>) => {
      if (payload < 0 || payload >= state.feedbacks.length) {
        return
      }
      state.feedback_index = payload
    },
    setCurrentFeedback: (state, { payload }: PayloadAction<SurveyFeedbackEntity | undefined>) => {
      state.current_feedback = payload
    },
    setHideNonAnswerQuestions: (state, { payload }: PayloadAction<boolean>) => {
      state.hide_non_answer_questions = payload
    },
    setFeedbacks: (state, { payload }: PayloadAction<SurveyFeedbackEntity[]>) => {
      state.feedbacks = payload
      state.feedback_index = 0
    },
    setFeedbackFilter: (state, { payload }: PayloadAction<ApiRequest.survey_manage.feedback_index>) => {
      state.feedback_filter = payload
    },
    setFeedbackFilterField: (state, { payload }: PayloadAction<Partial<ApiRequest.survey_manage.feedback_index>>) => {
      state.feedback_filter = {
        ...state.feedback_filter,
        ...payload
      }
    },
    setExportingStatistic: (state, { payload }: PayloadAction<boolean>) => {
      state.exporting_statistic = payload
    }
  }
})

export const { actions: surveyStatisticAction, reducer: surveyStatisticReducer } = slice
