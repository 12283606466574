import default_avatar from './default_avatar.png'
import sheets from './sheets.png'
import nps_emotion_0 from './nps_emotion_0.png'
import nps_emotion_1 from './nps_emotion_1.png'
import nps_emotion_2 from './nps_emotion_2.png'
import nps_emotion_3 from './nps_emotion_3.png'
import nps_emotion_4 from './nps_emotion_4.png'
import tkl_logo from './tkl_logo.png'
import google_voice from './google-voice.png'
import sticky_pin from './sticky_pin.png'
export const images = {
  default_avatar,
  sheets,
  nps_emotion_0,
  nps_emotion_1,
  nps_emotion_2,
  nps_emotion_3,
  nps_emotion_4,
  tkl_logo,
  google_voice,
  sticky_pin
}
