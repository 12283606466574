import { ApiRequest } from 'services/interface'
import { instanceAxios } from 'utils'

const show_survey = async (params: ApiRequest.survey_feedback.show_servey): Promise<any> =>
  instanceAxios.get(`/survey-customer/survey/${params.url_path}`, { params })
const send_feedback = async (survey_code: string, data: ApiRequest.survey_feedback.send_feedback): Promise<any> =>
  instanceAxios.post(`/survey-customer/survey/${survey_code}/feedback`, data)

export default {
  show_survey,
  send_feedback
}
