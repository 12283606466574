import { SurveyThemeConfig } from 'models'
import { store } from 'store'
import { COLORS } from 'utils'

export const createMarkup = (html: any) => {
  return { __html: html }
}

export const DEFAULT_THEME_CONFIG: SurveyThemeConfig = {
  main_color: COLORS.CelticBlue,
  background_color: COLORS.CelticBlue + '22'
}
