/* eslint-disable @typescript-eslint/naming-convention */
import { Checkbox, Radio, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { IconButton } from 'components'
import { EnumTable, TableRowAction, TableRowActionType } from 'models/table'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store'
import { useTableConfig, useTableData } from './hook'
import { isArray } from 'lodash'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined'

import { COLORS } from 'utils'
interface Props {
  data: any
  code: EnumTable
  onAction?: (action: TableRowActionType, item: any) => void
}

export function RowItem({ data, code, onAction }: Props) {
  const { config } = useTableConfig({ code })
  const { table } = useTableData(code)
  const columns = config?.columns ?? []
  const dispatch = useDispatch()
  // TODO
  const in_items = table?.select?.items.find((item) => item.id === data.id) !== undefined
  const select_all = table?.select?.select_all ?? false
  const selected = (in_items && !select_all) || (!in_items && select_all)

  const selectRow = useCallback(
    (item: any) => {
      dispatch(tableActions.selectRow({ item, code }))
    },
    [code, dispatch]
  )

  const ActionCell = () => {
    var actions = config.row_actions
    if (!actions || !isArray(actions)) {
      return <div />
    }
    return (
      <TableCell sx={{ flexDirection: 'row' }}>
        <Stack direction={'row'} alignItems={'center'} sx={{}}>
          {config?.row_actions?.map(({ action_type, label }) => {
            return (
              <IconButton
                label={label}
                icon={
                  action_type === 'view' ? (
                    <OpenInNewIcon style={{ fontSize: '18px', color: COLORS.DarkCharcoal }} />
                  ) : action_type === 'delete' ? (
                    <DeleteOutlineIcon style={{ fontSize: '18px', color: COLORS.DarkCharcoal }} />
                  ) : action_type === 'edit' ? (
                    <EditNoteIcon style={{ fontSize: '18px', color: COLORS.DarkCharcoal }} />
                  ) : action_type === 'create' ? (
                    <AddCircleOutlineIcon style={{ fontSize: '18px', color: COLORS.DarkCharcoal }} />
                  ) : action_type === 'preview' ? (
                    <PreviewOutlinedIcon style={{ fontSize: '18px', color: COLORS.DarkCharcoal }} />
                  ) : (
                    <div />
                  )
                }
                onClick={() => onAction?.(action_type, data)}
              />
            )
          })}
        </Stack>
      </TableCell>
    )
  }

  const has_row_actions = config && config.row_actions && config.row_actions.length > 0
  return (
    <TableRow sx={{}}>
      {config?.select && (
        <TableCell>
          {config.select?.multiple ? (
            <Checkbox checked={selected} onClick={() => selectRow(data)} />
          ) : (
            <Radio checked={selected} onClick={() => selectRow(data)} />
          )}
        </TableCell>
      )}
      {has_row_actions && config?.row_action_first && <ActionCell />}
      {columns.map(({ cell }) => {
        const { key, width, getValue, getElement } = cell
        if (getElement) {
          return <TableCell>{getElement(data)}</TableCell>
        }
        //  TODO
        const max_len = width ? width / 2 : 50
        const label = key ? (data[key] ?? '') : getValue ? `${getValue(data)}` : ''
        const trimmed = label.length > max_len
        const trimLabel = trimmed ? `${label.slice(0, max_len)}...` : label
        return (
          <TableCell size="small" sx={{ cursor: trimmed ? 'pointer' : null, minWidth: width ? `${width}px` : '140px' }} align="center">
            {trimmed ? (
              <Tooltip title={label} placement="top" followCursor arrow>
                <Typography sx={{ fontSize: '13px', fontWeight: '400', color: '#000' }}>{trimLabel}</Typography>
              </Tooltip>
            ) : (
              <Typography sx={{ fontSize: '13px', fontWeight: '400', color: '#000' }}>{label}</Typography>
            )}
          </TableCell>
        )
      })}
      {has_row_actions && !config?.row_action_first && <ActionCell />}
    </TableRow>
  )
}
