/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  AnswerEntity,
  QuestionType,
  SurveyManageState,
  SurveyEntity,
  SurveyPartItem,
  SurveyQuestionEntity,
  SurveyFeedbackEntity,
  QuestionTypeName,
  SurveyImageSlideEntity,
  SurveyHtmlParagraph,
  QuestionOptionList,
  QuestionOptionEntity,
  SurveyManageSection,
  DisplayCondition,
  SurveyThemeConfig
} from 'models'
import { randomString } from 'utils'

const initialState: SurveyManageState = { answers: [], editable_codes: [], options_list: [] }

const getSampleQuestion = (type: QuestionType, code: string) => {
  var result: Partial<SurveyQuestionEntity> = { code, name: 'Chưa có tiêu đề', type, visible: true }
  switch (type) {
    case 'short_answer':
    case 'paragraph':
    case 'date':
      break
    case 'dropdown':
    case 'multiple_choice':
    case 'checkboxes':
      console.log('Get sample question: ', type)
      result.options = [1, 2, 3].map((u) => ({ code: randomString(8), type: type === 'checkboxes' ? 'checkbox' : 'radio', label: `Câu trả lời ${u}` }))
      break
    case 'checkboxes_grid':
    case 'multiple_choice_grid':
      result.options = [1, 2, 3].map((u) => ({ code: randomString(8), type: 'index', label: `Tiêu chí đánh giá ${u}` }))

      result.options = result.options.concat(
        [1, 2, 3].map((u) => ({ code: randomString(8), type: type === 'checkboxes_grid' ? 'checkbox' : 'radio', label: `Mức ${u}` }))
      )
      break
    case 'nps':
      result.options = ['Rất tệ', 'Tệ', 'Bình thường', 'Tốt', 'Rất tốt'].map((u, i) => ({
        code: randomString(8),
        mapping_value: i + 1,
        label: u,
        type: 'radio'
      }))

      break
  }
  return result as SurveyQuestionEntity
}
const addPartToSurvey = (state: SurveyManageState, part: SurveyPartItem) => {
  if (!state.survey) {
    return
  }

  state.survey.arrangement.unshift(part)
}
const slice = createSlice({
  initialState,
  name: 'survey_manage',
  reducers: {
    setSurvey: (state, { payload }: PayloadAction<SurveyEntity | undefined>) => {
      if (payload == undefined) {
        state.survey = undefined
        return
      }
      if (payload?.id !== state.survey?.id) {
        state.focus_section = 'information'
      } else {
      }
      state.survey = { ...payload }
      state.answers = []
    },
    focusSection: (state, { payload }: PayloadAction<SurveyManageSection | undefined>) => {
      if (state.focus_section === payload) {
        state.focus_section = undefined
        return
      }
      state.focus_section = payload
    },
    setOptionsList: (state, { payload }: PayloadAction<QuestionOptionList[]>) => {
      state.options_list = payload
    },
    setFeedback: (state, { payload }: PayloadAction<SurveyFeedbackEntity | undefined>) => {
      if (payload == undefined) {
        state.survey = undefined
        return
      }
      state.focus_feedback = undefined
    },
    setAnswers: (state, { payload }: PayloadAction<AnswerEntity[]>) => {
      if (payload == undefined) {
        state.survey = undefined
        return
      }
      state.answers = payload
    },
    deleteImageSlide: (state, { payload }: PayloadAction<{ code: string }>) => {
      const { survey } = state
      if (!survey) {
        return
      }

      survey.image_slides = survey.image_slides?.filter((u) => u.code !== payload.code) ?? []
      survey.arrangement = survey.arrangement?.filter((u) => u.code !== payload.code) ?? []
    },
    changeImageSlide: (state, { payload }: PayloadAction<{ code: string; data: Partial<SurveyImageSlideEntity> }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      var idx = survey.image_slides.findIndex((u) => u.code === payload.code)
      if (idx === -1) {
        return
      }
      survey.image_slides[idx] = { ...survey.image_slides[idx], ...payload.data }
    },
    addImageSlide: (state) => {
      const { survey } = state
      if (!survey) {
        return
      }
      if (!survey.image_slides) {
        survey.image_slides = []
      }
      var code = randomString(8)
      survey.image_slides.push({ name: '............', images: [], code })
      addPartToSurvey(state, { type: 'image_slide', code })
    },

    deleteHtmlParagraph: (state, { payload }: PayloadAction<{ code: string }>) => {
      const { survey } = state
      if (!survey) {
        return
      }

      survey.html_paragraphs = survey.html_paragraphs?.filter((u) => u.code !== payload.code) ?? []
      survey.arrangement = survey.arrangement?.filter((u) => u.code !== payload.code) ?? []
    },
    changeHtmlParagraph: (state, { payload }: PayloadAction<{ code: string; data: Partial<SurveyHtmlParagraph> }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      var idx = survey.html_paragraphs.findIndex((u) => u.code === payload.code)
      if (idx === -1) {
        return
      }
      survey.html_paragraphs[idx] = { ...survey.html_paragraphs[idx], ...payload.data }
    },
    addHtmlParagraph: (state) => {
      const { survey } = state
      if (!survey) {
        return
      }
      if (!survey.html_paragraphs) {
        survey.html_paragraphs = []
      }
      var code = randomString(8)
      survey.html_paragraphs.push({ name: '...............', code, content: '' })
      addPartToSurvey(state, { type: 'html_paragraph', code })
    },
    addQuestion: (state, { payload }: PayloadAction<{ type: QuestionType }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      // id?: string
      // value: string
      // type: QuestionOptionType
      var question_code = randomString(8)

      survey.questions.push(getSampleQuestion(payload.type, question_code))

      addPartToSurvey(state, { type: 'question', code: question_code })
    },
    deleteQuestion: (state, { payload }: PayloadAction<{ code: string }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      survey.questions = survey.questions.filter((u) => u.code !== payload.code)
      survey.arrangement = survey.arrangement.filter((u) => u.code !== payload.code)
    },
    changeQuestion: (state, { payload }: PayloadAction<{ code: string; data: Partial<SurveyQuestionEntity> }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      var idx = survey.questions.findIndex((u) => u.code === payload.code)
      if (idx === -1) {
        return
      }
      survey.questions[idx] = { ...survey.questions[idx], ...payload.data }
    },
    changeQuestionOption: (state, { payload }: PayloadAction<{ question_code: string; data: Partial<QuestionOptionEntity> }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      var ques_idx = survey.questions.findIndex((u) => u.code === payload.question_code)
      console.log('Question idx', ques_idx)
      if (ques_idx === -1) {
        return
      }

      var option = survey.questions[ques_idx].options.find((u) => u.code === payload.data.code)
      console.log('option idx idx', option)
      if (!option) {
        return
      }

      survey.questions[ques_idx].options = survey.questions[ques_idx].options.map((u) => (u.code === payload.data.code ? { ...option, ...payload.data } : u))
    },
    selectPart: (state, { payload }: PayloadAction<{ code: string }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      if (state.editable_codes.includes(payload.code)) {
        state.editable_codes = state.editable_codes.filter((u) => u !== payload.code)
        return
      }
      state.editable_codes.push(payload.code)
    },
    updatePart: (state, { payload }: PayloadAction<Partial<SurveyPartItem>>) => {
      const { survey } = state
      if (!survey) {
        return
      }

      var idx = state.survey.arrangement.findIndex((u) => u.code === payload.code)
      if (idx === -1) {
        return
      }
      state.survey.arrangement[idx] = { ...state.survey.arrangement[idx], ...payload }
    },

    updateSurveyThemeConfig: (state, { payload }: PayloadAction<Partial<SurveyThemeConfig>>) => {
      const { survey } = state
      if (!survey) {
        return
      }

      state.survey.theme_config = payload
    },
    arrangePart: (state, { payload }: PayloadAction<{ arrangement: SurveyPartItem[] }>) => {
      const { survey } = state
      if (!survey) {
        return
      }

      state.survey.arrangement = payload.arrangement
    },
    addDisplayCondition: (state, { payload }: PayloadAction<DisplayCondition>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      if (!survey.display_conditions) {
        survey.display_conditions = []
      }
      survey.display_conditions.push(payload)
    },
    deleteDisplayCondition: (state, { payload }: PayloadAction<{ code: string }>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      if (!survey.display_conditions) {
        survey.display_conditions = []
      }
      survey.display_conditions = survey.display_conditions.filter((u) => u.code !== payload.code)
    },
    updateDisplayCondition: (state, { payload }: PayloadAction<Partial<DisplayCondition>>) => {
      const { survey } = state
      if (!survey) {
        return
      }
      if (!survey.display_conditions) {
        survey.display_conditions = []
      }
      var idx = survey.display_conditions.findIndex((u) => u.code === payload.code)
      if (idx === -1) {
        return
      }
      survey.display_conditions[idx] = { ...survey.display_conditions[idx], ...payload }
    }
  }
})

export const { actions: surveyManageActions, reducer: surveyEditReducer } = slice
