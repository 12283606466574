import { FormControl, Typography } from '@mui/material'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useUI } from 'hooks'
import moment, { Moment } from 'moment'
import React, { CSSProperties } from 'react'
import { COLORS } from 'utils'
import { InputLabel } from './InputLabel'
interface Props {
  defaultValue?: string
  value?: string
  label?: string
  textStyle?: CSSProperties
  disabled?: boolean
  caption?: string
  focus_color?: string
  placeholder?: string
  show_hint?: boolean
  require?: boolean
  handleChange: (a: string) => void
}
export function DateInput({ value, textStyle, defaultValue, label, disabled, caption, require, show_hint, focus_color, handleChange }: Props) {
  const { platform } = useUI()
  return (
    <FormControl fullWidth>
      {label && <InputLabel label={label} require={require} caption={caption} />}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateField
          onChange={(u: Moment) => {
            try {
              if (!u.isValid) {
                return
              }
              var date_str = u.toDate().toISOString()
              console.log('On change : ', u, date_str)
              handleChange(date_str)
            } catch (e) {}
          }}
          disabled={disabled}
          defaultValue={defaultValue ? moment(defaultValue) : undefined}
          format="DD-MM-YYYY"
          InputProps={{ sx: { height: 40 } }}
          sx={{
            '& .MuiInputBase-input': {
              fontFamily: 'Be Vietnam Pro, sans-serif',
              fontSize: '16px',
              fontWeight: '400',
              color: COLORS.DarkCharcoal,
              ...(textStyle ?? {})
            },
            '& .MuiOutlinedInput-root': {
              '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: focus_color ?? COLORS.DarkCharcoal, borderWidth: '1px' },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: focus_color ?? COLORS.DarkCharcoal, borderWidth: '1px' }
            }
          }}
        />
      </LocalizationProvider>
      {show_hint && (
        <Typography sx={{ fontSize: platform === 'mobile' ? '10px' : '12px', fontWeight: '400', color: COLORS.nickel, marginTop: '4px' }}>
          * Thứ tự nhập là Ngày-tháng-năm, ví dụ: 31-12-1999
        </Typography>
      )}
    </FormControl>
  )
}
