import { ArrowDropDown } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { COLORS } from 'utils'

interface Props {
  label: string
  children: any
  defaultExpand?: boolean
  onPressHeader?: () => void
}
export function Section({ label, children, defaultExpand, onPressHeader }: Props) {
  const [expand, setExpand] = useState(defaultExpand ?? false)

  useEffect(() => {
    setExpand(defaultExpand)
  }, [defaultExpand])

  return (
    <Stack direction="column" sx={{ mb: 2, borderRadius: '4px', overflow: 'hidden' }}>
      <Stack
        direction="row"
        alignItems="center"
        onClick={() => {
          if (onPressHeader) {
            onPressHeader()
            return
          }
          setExpand(!expand)
        }}
        sx={{
          backgroundColor: COLORS.SectionHeaderBg,
          px: 1,
          py: 1.2,
          '&:hover': {
            cursor: 'pointer',
            opacity: 0.8
          }
        }}
      >
        <Typography
          style={{
            fontSize: '15px',
            color: COLORS.AuroMetalSaurus,
            fontWeight: '500',
            flex: 1
          }}
        >
          {label}
        </Typography>
        <ArrowDropDown sx={{ color: COLORS.AuroMetalSaurus, fontSize: '26px' }} />
      </Stack>
      {expand && <Stack sx={{ backgroundColor: '#fff', px: 2, py: 1 }}>{children}</Stack>}
    </Stack>
  )
}
