export type ValidateFormat = 'email' | 'phone'
export class Validator {
  private static email = (str: string) => {
    return str.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi) !== null
  }

  private static phone = (str: string) => {
    const reg = /((09|03|07|08|05)+([0-9]{8})\b)/g
    if (reg.test(`${str}`)) {
      return true
    }
    return false
  }

  public static validate = (str: string, format: ValidateFormat) => {
    if (format === 'email') {
      return this.email(str)
    }
    if (format === 'phone') {
      return this.phone(str)
    }
    return true
  }
}
