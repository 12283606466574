import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'hooks'
import { CareStatusCaption, CareStatusColor, CareStatusDesc, CareStatusLabel, CustomerCareEntity, EnumCareStatus, EnumPromoType, MenuUrl } from 'models'
import React, { useState } from 'react'
import { COLORS, formatDateEn } from 'utils'
import { CareLog } from '../brand_care/care_log'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Promos } from '../promos'
function Field({ label, value }: { label: string; value: string }) {
  return (
    <Stack sx={{}} direction={'row'} alignItems={'center'}>
      <Typography style={{ fontSize: '12px', color: COLORS.nickel, minWidth: '100px' }}>{label}</Typography>
      <Typography style={{ fontSize: '12px', fontWeight: '500', color: COLORS.DarkCharcoal }}>{value}</Typography>
    </Stack>
  )
}

function CampaignCare({ data, defaulExpand }: { data: CustomerCareEntity; defaulExpand?: boolean }) {
  const { id, campaign, status, fields, unit_carer, created_at, carer, logs, promos, used_promos } = data
  const [expanded, setExpanded] = useState<boolean>(defaulExpand ?? false)
  console.log('Expand: ', expanded)
  return (
    <Stack direction={'column'}>
      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => {
            window.open(`${MenuUrl.campaign}/detail/${campaign?.id}`, '_blank', 'noopener,noreferrer')
          }}
        >
          <Tooltip title="Xem chi tiết đợt chăm sóc">
            <OpenInNewIcon sx={{ fontSize: '18px' }} />
          </Tooltip>
        </Box>
        <Stack direction={'column'} sx={{ display: 'flex', flex: 1, ml: 2, cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              color: COLORS.AuroMetalSaurus
            }}
          >
            {campaign?.name}
          </Typography>
          <Field label="Tạo lúc:" value={formatDateEn(created_at)} />
          {unit_carer && <Field label="Cửa hàng:" value={unit_carer.name?.replace(/Tokyolife/gi, 'T ').replace(/Format/gi, 'F ')} />}
          {carer && <Field label="Nhân viên:" value={carer.name} />}
          <Field label="Trạng thái:" value={CareStatusDesc[status]} />
        </Stack>
        <ArrowBackIosNewIcon sx={{ fontSize: '16px', transform: expanded ? 'rotate(270deg)' : 'rotate(180deg)' }} />
      </Stack>
      {expanded ? (
        <>
          <Grid container columnSpacing={4}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '13px', color: COLORS.DarkCharcoal, fontWeight: '500', textAlign: 'center', marginTop: '8px' }}>
                {logs.length === 0 ? 'Chưa có lịch sử liên hệ' : 'Lịch sử liên hệ'}
              </Typography>
              {logs.map((log) => (
                <CareLog data={log} />
              ))}
            </Grid>
            <Grid item xs={6}>
              <Promos data={data} />
            </Grid>
          </Grid>
        </>
      ) : (
        <div />
      )}
    </Stack>
  )
}
export function CampaignCares() {
  const { care } = useSelector((x) => x.care)
  if (!care) {
    return <div />
  }

  var campaign_cares = [...(care.campaign_cares ?? [])]

  if (campaign_cares.length === 0) {
    return <Typography style={{ fontSize: '14px', color: COLORS.DarkCharcoal, margin: '12px' }}>Khách chưa thuộc đợt chăm sóc nào trước đó.</Typography>
  }

  campaign_cares.sort((u, v) => (new Date(u.created_at) > new Date(v.created_at) ? -1 : 1))
  return (
    <Stack direction={'column'}>
      {campaign_cares.map((item: CustomerCareEntity, index: number) => (
        <>
          {index > 0 ? <Box sx={{ height: '8px', backgroundColor: COLORS.BrightGray2, my: 1.5 }} /> : <div />}
          <CampaignCare data={item} defaulExpand={index === 0} />
        </>
      ))}
    </Stack>
  )
}
