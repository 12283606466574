import { FormControl, FormHelperText, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { InputLabel } from './InputLabel'

interface Props extends Omit<TextFieldProps, 'variant'> {
  iconstart?: React.JSX.Element
  width?: any
  placeholder?: string
  label?: string
  require?: boolean
  backgroundColor?: string
  caption?: string
  textcolor?: string
  handleChange?: (value: string) => void
  err_msg?: string
}
export function MyTextField({
  textcolor,
  caption,
  placeholder,
  err_msg,
  backgroundColor,
  disabled,
  label,
  width,
  require,
  iconstart,
  type,
  handleChange,
  ...others
}: Props) {
  return (
    <FormControl fullWidth>
      {label && <InputLabel label={label} require={require} caption={caption} />}
      <TextField
        {...others}
        placeholder={placeholder ?? ''}
        disabled={disabled}
        onChange={(e) => handleChange?.(e.target.value)}
        autoComplete="new-password"
        sx={{
          width: `${width || '100%'} !important`,
          backgroundColor: disabled ? '#F5F5F5' : '#FFFFFF',
          color: `${textcolor || '#333333'}`,
          borderRadius: '6px',
          border: '1px solid #EEEEEE',
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
          '& input[readonly]': { cursor: 'pointer', userSelect: 'none' },
          '& .MuiOutlinedInput-root': {
            fontFamily: 'Be Vietnam Pro, sans-serif',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#525252',
            '& fieldset': { borderColor: '#EEEEEE' },
            '&:hover fieldset': { borderColor: '#EEEEEE' },
            '&.Mui-focused fieldset': { borderColor: '#2563EB' }
          },
          '& input:-webkit-autofill': { '-webkit-box-shadow': '0 0 0 50px white inset;', '-webkit-text-fill-color': '#333;' },

          '& input:-webkit-autofill:focus': { '-webkit-box-shadow': '0 0 0 50px white inset;', '-webkit-text-fill-color': '#333;' }
        }}
        variant="outlined"
        size="small"
      />
      {err_msg && <FormHelperText error>{err_msg}</FormHelperText>}
    </FormControl>
  )
}
