import { Stack, Typography } from '@mui/material'
import { JSX } from 'react'
import { Button } from './Button'
import { COLORS } from 'utils'
import React from 'react'

interface Props {
  actions: {
    label: string
    onSelect: () => void
  }[]
  label?: string
}
export function ActionGroup({ actions, label }: Props) {
  return (
    <Stack>
      {label && <Typography style={{ fontSize: '14px', color: COLORS.DarkCharcoal, fontWeight: '500' }}>{label}</Typography>}
      <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ mt: 1 }}>
        {actions.map((action) => (
          <Button
            label={action.label}
            onClick={action.onSelect}
            labelStyle={{ fontSize: '12px', fontWeight: '500', color: COLORS.DarkCharcoal }}
            style={{ padding: '2px 10px', backgroundColor: COLORS.MellowApricot }}
          />
        ))}
      </Stack>
    </Stack>
  )
}
