import { CampaignEntity, EnumBrand } from './campaign'
import { CareLogEntity } from './care_log'
import { CustomerEntity, ImportCustomerEntity } from './customer'
import { EntityBase } from './entity'
import { OrgUnitEntity } from './org_unit'
import { PromoCode } from './promo_code'
import { DistributeUnit } from './redux'
import { SurveyFeedbackEntity } from './survey'
import { UserEntity } from './user_portal'

export enum EnumCareStatus {
  FIND_UNIT_CARER = 'find_unit_carer',
  FIND_CARER = 'find_carer',
  NOT_CONTACT_YET = 'not_contact_yet',
  UNABLE_CONTACT = 'unable_contact',
  CONTACT_DONE = 'contact_done'
}

export interface DistributeConfig {
  units: DistributeUnit[]
}
export interface StatCustomerCare {
  id?: string
  distribute_config?: DistributeConfig
  counter: Partial<Record<EnumCareStatus, number>>
  belong_count?: number

  units?: StatCustomerCare[]
}

export const CareStatusColor: Record<EnumCareStatus, string> = {
  find_unit_carer: '#cfbcab',
  find_carer: '#AAb8BB',
  not_contact_yet: '#EFC16C',
  unable_contact: '#C96349',
  contact_done: '#80BEAF'
}

export enum EnumCareType {
  CAMPAIGN = 'campaign',
  BRAND = 'brand'
}

export interface CustomerCareEntity extends EntityBase {
  customer?: CustomerEntity
  code: string
  unit_store?: OrgUnitEntity
  unit_carer?: OrgUnitEntity
  carer?: UserEntity
  campaign?: CampaignEntity
  brand?: EnumBrand
  campaign_cares?: CustomerCareEntity[]
  campaigns?: CampaignEntity[]
  care_type: EnumCareType
  logs?: CareLogEntity[]
  status: EnumCareStatus
  survey_feedback?: SurveyFeedbackEntity
  fields?: { label: string; value: string }[]
  slug: string
  carer_note: string
  revenue?: number
  used_promos?: PromoCode[]
  promo_slug: string
  promos?: PromoCode[]
  created_at: string
}

export const CareStatusLabel: Record<EnumCareStatus, string> = {
  [EnumCareStatus.FIND_UNIT_CARER]: 'Tìm cửa hàng',
  [EnumCareStatus.FIND_CARER]: 'Tìm nhân viên',
  [EnumCareStatus.NOT_CONTACT_YET]: 'Chưa gọi',
  [EnumCareStatus.UNABLE_CONTACT]: 'Không gọi được',
  [EnumCareStatus.CONTACT_DONE]: 'Đã liên hệ'
}

export const CareStatusDesc: Record<EnumCareStatus, string> = {
  [EnumCareStatus.FIND_UNIT_CARER]: 'Chưa tìm được cửa hàng liên hệ',
  [EnumCareStatus.FIND_CARER]: 'Chưa tìm được nhân viên liên hệ',
  [EnumCareStatus.NOT_CONTACT_YET]: 'Nhân viên chưa liên hệ cho khách',
  [EnumCareStatus.UNABLE_CONTACT]: 'Nhân viên không thể liên hệ với khách',
  [EnumCareStatus.CONTACT_DONE]: 'Nhân viên đã liên hệ khách'
}
export const CareStatusCaption: Record<EnumCareStatus, string> = {
  [EnumCareStatus.FIND_UNIT_CARER]: 'Khách chưa được phân bổ xuống cửa hàng nào',
  [EnumCareStatus.FIND_CARER]: 'Khách đã chia về cửa hàng, nhưng Quản lý cửa hàng chưa chỉ định nhân viên nào sẽ gọi',
  [EnumCareStatus.NOT_CONTACT_YET]: 'Khách đã được chia về nhân viên cửa hàng, nhưng nhân viên chưa liên hệ cho khách',
  [EnumCareStatus.UNABLE_CONTACT]: 'Nhân viên không thể liên hệ cho khách (máy bận, sai số, ...)',
  [EnumCareStatus.CONTACT_DONE]: 'Nhân viên đã liên hệ chăm sóc khách.'
}

export enum EnumCustomerCareSortField {
  SCORE_AMOUNT = 'SCORE_AMOUNT',
  SCORE_EXPIRE = 'SCORE_EXPIRE',
  BIRTHDAY = 'BIRTHDAY',
  REVENUE = 'REVENUE',
  FM_RANK = 'FM_RANK'
}

export const CustomerCareSortFields: EnumCustomerCareSortField[] = [
  EnumCustomerCareSortField.FM_RANK,
  EnumCustomerCareSortField.BIRTHDAY,
  EnumCustomerCareSortField.REVENUE,
  EnumCustomerCareSortField.SCORE_AMOUNT,
  EnumCustomerCareSortField.SCORE_EXPIRE
]
export const CustomerCareSortLabel: Record<EnumCustomerCareSortField, string> = {
  [EnumCustomerCareSortField.FM_RANK]: 'Theo hạng Format',
  [EnumCustomerCareSortField.BIRTHDAY]: 'Theo sinh nhật',
  [EnumCustomerCareSortField.REVENUE]: 'Theo doanh thu',
  [EnumCustomerCareSortField.SCORE_AMOUNT]: 'Theo lượng điểm tích luỹ',
  [EnumCustomerCareSortField.SCORE_EXPIRE]: 'Theo hạn điểm'
}
