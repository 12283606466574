import { FormControl, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputColor from 'react-input-color'
import { InputLabel } from './InputLabel'
import { COLORS } from 'utils'
import { showToast } from './GlobalMessage'

interface Props {
  label?: string
  require?: boolean

  value?: string
  onChange: (val: string) => void
}

export function ColorInput({ value, label, require, onChange }: Props) {
  const [color, setColor] = useState<string>(value)
  const [text, setText] = useState<string>('')
  useEffect(() => {
    setText(color)
  }, [color])

  useEffect(() => {
    if (color === value) {
      return
    }
    onChange(color)
  }, [color])

  return (
    <FormControl fullWidth>
      {label && <InputLabel label={label} require={require} />}
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <input
          value={text}
          style={{
            width: '120px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: '1px',
            borderRadius: '3px',
            padding: '3px 12px',
            borderColor: COLORS.nickel
          }}
          maxLength={7}
          placeholder="Nhập mã hexa"
          onChange={(e) => {
            var val = e.target.value
            setText(val)
            if (/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/.test(val)) {
              setColor(val)
            }
          }}
        />

        {/* <InputColor
          initialValue={color ?? '#fff'}
          onChange={(c) => {
            if (c == null) {
              return
            }
            console.log('C hex: ', c.hex, c.hex.length)
            if (c.hex.length > 7) {
              showToast({ content: 'Chỉ chọn được mã màu 6 số', type: 'info' })
              return
            }
            setColor(c.hex)
          }}
          placement="right"
        /> */}
      </Stack>
    </FormControl>
  )
}
