/* eslint-disable react/require-default-props */
import { makeStyles } from '@material-ui/core'
import { FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material'
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import { InputLabel } from 'components'
import _, { isObject } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { COLORS, instanceAxios } from 'utils'

interface Props<ItemT>
  extends Omit<AutocompleteProps<ItemT, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput' | 'options' | 'getOptionLabel'> {
  url?: string
  method?: 'get' | 'post'
  label?: string
  caption?: string
  placeholder?: string
  err_msg?: string
  require?: boolean
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
  options?: ItemT[]
  fetch_on_initial?: boolean
  getOptionLabel?: (item: ItemT) => string
  getOptionCaption?: (item: ItemT) => string
  selectOption?: (item: ItemT) => void
  selectOptions?: (items: ItemT[]) => void
  filterFunc?: (item: ItemT, searchKey: string) => boolean
}

export function DropdownPicker<ItemT>({
  url,
  fetch_on_initial,
  placeholder,
  method,
  options,
  caption,
  fullWidth,
  value,
  label,
  err_msg,
  require,
  isOptionEqualToValue,
  renderInput,
  getOptionLabel,
  selectOption,
  selectOptions,
  filterFunc,
  getOptionCaption,
  disabled,
  ...others
}: Props<ItemT>) {
  const [inputValue, setInputValue] = useState<string>('')
  const [searchKey, setSearchKey] = useState<string>('')
  const [data, setData] = useState<ItemT[]>([])
  const debouncedSearch = useCallback(
    _.debounce((value) => {
      setSearchKey(value)
    }, 1000),
    [] // Empty dependency array so it is created once
  )

  const fetchData = useCallback(
    async (enable_empty_search: boolean = false) => {
      if (!url) {
        return
      }
      if (!fetch_on_initial) {
        if (!enable_empty_search && searchKey.length === 0) {
          return
        }
      }
      const requestParams = { search: searchKey }

      instanceAxios({ url, method, ...(method === 'post' ? { data: requestParams } : { params: requestParams }) })
        .then((response) => {
          // Check if not pagination
          const res = response?.data?.data ?? {}
          setData(res.pagination ? (res.data ?? []) : (res ?? []))
        })
        .catch((e) => {})
    },
    [method, searchKey, url, fetch_on_initial]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderLabel = useCallback(
    (option: any) => {
      if (option == null) {
        return ''
      }
      return getOptionLabel ? getOptionLabel?.(option) : typeof option === 'string' || option instanceof String ? (option as string) : ''
    },
    [getOptionLabel]
  )

  const classes = useStyles()
  return (
    <FormControl fullWidth={fullWidth === undefined ? true : fullWidth}>
      {label && <InputLabel label={label} require={require} caption={caption} />}
      <Autocomplete
        disabled={disabled}
        disableCloseOnSelect
        onFocus={() => fetchData(true)}
        // TODO
        onChange={(_e, v: any) => (Array.isArray(v) ? selectOptions?.(v as ItemT[]) : selectOption?.(v as ItemT))}
        size="small"
        className={classes.select}
        value={value}
        defaultValue={value}
        filterOptions={(options) => (filterFunc ? options.filter((option) => filterFunc(option, inputValue)) : options)}
        options={options ? [...options] : [...data]}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderOption={(props, option) => (
          <Stack {...props} style={{ alignItems: 'flex-start' }}>
            <Typography style={{ fontSize: '13px', color: COLORS.DarkCharcoal }}>{renderLabel(option)}</Typography>
            {getOptionCaption && <Typography style={{ fontSize: '12px', color: COLORS.nickel }}>{getOptionCaption(option)}</Typography>}
          </Stack>
        )}
        getOptionLabel={(option: string | ItemT) => renderLabel(option)}
        isOptionEqualToValue={isOptionEqualToValue || ((a: any, b: any) => (isObject(a) && isObject(b) ? a?.id === b?.id : a === b))}
        renderInput={
          renderInput ||
          ((params) => (
            <TextField
              {...params}
              style={{ fontSize: '12px', color: 'red' }}
              fullWidth
              placeholder={placeholder ?? 'Nhập'}
              onChange={(e: any) => {
                debouncedSearch(e.target.value)
              }}
            />
          ))
        }
        sx={{ '& .MuiAutocomplete-tag': { backgroundColor: '#FFF' } }}
        {...others}
      />
      {err_msg && <FormHelperText error>{err_msg}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Be Vietnam Pro, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#525252',
    marginBottom: '8px'
  },
  select: {
    '& input': { fontSize: '13px' },
    '& .MuiInputBase-root': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '13px',
      borderRadius: '4px',
      '& fieldset': { border: '1px solid #333' },
      '&:focus-visible, &:hover': { '& fieldset': { border: '1px solid #333' } }
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #333', borderRadius: '6px' },
    '& .MuiAutocomplete-tag': { borderRadius: 0 }
  }
}))
