import { DEFAULT_THEME_CONFIG } from 'components/survey/question_view/common'
import { useSelector } from './common'

export const useSurveyTheme = () => {
  const { survey: survey_1 } = useSelector((x) => x.survey_manage)
  const { survey: survey_2 } = useSelector((x) => x.survey_feedback)
  const theme_config = survey_1?.theme_config ?? survey_2?.theme_config ?? DEFAULT_THEME_CONFIG

  const main_color = theme_config.main_color
  const background_color = theme_config.background_color
  return { theme_config, main_color, background_color }
}
