import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnswerEntity, AnswerErrorType, CustomerEntity, SpecialField, SurveyEntity, SurveyFeedbackEntity, SurveyMode, SurveyQuestionEntity } from 'models'
import { SurveyFeedbackState, SurveyFeedbackStep } from 'models/redux/survey_feedback'
import { Validator } from 'utils'

export const isNotAnswered = (question: SurveyQuestionEntity, answer?: AnswerEntity) => {
  if (!answer) {
    return true
  }
  switch (question.type) {
    case 'short_answer':
    case 'paragraph':
    case 'date':
      return !answer.answer || answer.answer.length === 0
    case 'dropdown':
    case 'checkboxes':
    case 'multiple_choice':
    case 'checkboxes_grid':
    case 'multiple_choice_grid':
      return !answer.answers || answer.answers.length === 0
    default:
      return true
  }
}

export const validRequire = (question: SurveyQuestionEntity, answer?: AnswerEntity): boolean => {
  if (!question.required) {
    return true
  }
  if (!answer) {
    return false
  }
  // Check required
  switch (question.type) {
    case 'short_answer':
    case 'paragraph':
    case 'date':
      return !(!answer.answer || answer.answer?.length === 0)
    case 'checkboxes':
    case 'dropdown':
    case 'multiple_choice':
      return !(!answer.answers || answer.answers.length === 0)
    case 'checkboxes_grid':
    case 'multiple_choice_grid':
      const rows = question.options.filter((item) => item.type === 'index')
      const non_answer = rows.find((row) => !answer.answers?.find((item: string) => item.startsWith(row.code)))
      return non_answer === undefined
    default:
      return true
  }
}
export const validFormat = (question: SurveyQuestionEntity, answer?: AnswerEntity): boolean => {
  switch (question.type) {
    case 'short_answer':
      const answer_content = answer?.answer ?? ''
      if (question.answer_format === 'email') {
        return Validator.validate(answer_content, 'email')
      }
      if (question.answer_format === 'phone_number') {
        return Validator.validate(answer_content, 'phone')
      }
      return true
    default:
      return true
  }
}

const initUserInfoAnswers = (survey: SurveyEntity, customer: CustomerEntity | undefined) => {
  if (!customer) {
    return []
  }
  var fields: SpecialField[] = ['customer_name', 'customer_phone']
  var answers: AnswerEntity[] = []
  for (var field of fields) {
    var question = survey.questions.find((u) => u.special_field === field)
    if (!question) {
      continue
    }
    answers.push({
      question_code: question.id,
      answer: field === 'customer_name' ? (customer.name ?? '') : field === 'customer_phone' ? (customer.phone ?? '') : (customer.email ?? '')
    } as AnswerEntity)
  }
  return answers
}
export const checkAnswer = (question: SurveyQuestionEntity, answer?: AnswerEntity): AnswerErrorType | null => {
  // TODO: error - not require - empty answer - still check other

  if (question.required) {
    if (!validRequire(question, answer)) {
      return 'empty_answer'
    }
  }
  if (!validFormat(question, answer)) {
    return 'wrong_format'
  }
  return null
}

const initialState: SurveyFeedbackState = {
  answers: [],
  errors: {},
  step: 'customer_answer_survey',
  scroll_to_first_unanswered: false,
  ready_to_answer: false,
  exporting_thanks: false
}

const slice = createSlice({
  initialState,
  name: 'survey_feedback',
  reducers: {
    setSurvey: (
      state,
      {
        payload
      }: PayloadAction<{
        survey: SurveyEntity | undefined
        current_feedback?: SurveyFeedbackEntity
        customer?: CustomerEntity
        mode: SurveyMode
        step: SurveyFeedbackStep
      }>
    ) => {
      if (payload == undefined) {
        state.survey = undefined
        return
      }
      state.customer = payload.customer
      state.survey = { ...payload.survey }
      state.current_feedback = payload.current_feedback
      state.mode = payload.mode
      state.step = payload.step
      if (payload.current_feedback) {
        state.answers = payload.current_feedback.answers.map((u) => ({ ...u, question_code: u.question?.code }))
        state.thanks_template = payload.survey.thanks_template
        state.thanks_params = { code: payload.current_feedback.codes?.[0]?.value }
        state.errors = {}
        return
      }

      state.answers = initUserInfoAnswers(payload.survey, payload.customer)

      if (payload.survey) state.errors = {}

      for (var question of state.survey.questions) {
        if (!question.required) {
          continue
        }
        if (question.special_field && payload.customer) {
          continue
        }
        state.errors[question.id] = 'empty_answer'
      }
    },
    setCustomerPhone: (state, { payload }: PayloadAction<string | undefined>) => {
      state.customer_phone = payload
    },
    setReadyToAnswer: (state, { payload }: PayloadAction<boolean>) => {
      state.ready_to_answer = payload
    },
    setExportingImage: (state, { payload }: PayloadAction<boolean>) => {
      state.exporting_thanks = payload
    },
    setScrollToFirstUnanswerd: (state, { payload }: PayloadAction<boolean>) => {
      if (!state.survey) {
        return
      }
      state.scroll_to_first_unanswered = payload
    },
    completeSubmit: (state, { payload }: PayloadAction<{ template?: string; params: any } | undefined>) => {
      if (!state.survey) {
        return
      }
      state.step = 'customer_view_thanks'
      state.thanks_template = payload?.template
      state.thanks_params = payload?.params
    },

    setCurrentFeedback: (state, { payload }: PayloadAction<SurveyFeedbackEntity | undefined>) => {
      state.current_feedback = payload
      state.answers = payload.answers ?? []
      if (payload) {
        state.mode = 'only-view'
      }
    },
    answer: (state, { payload }: PayloadAction<{ answer: AnswerEntity; question: SurveyQuestionEntity }>) => {
      const i = state.answers.findIndex((item) => item.question_code === payload.answer.question_code)
      if (i !== -1) {
        state.answers[i] = payload.answer
      } else {
        state.answers.push(payload.answer)
      }
      if (!state.errors) {
        state.errors = {}
      }
      if (state.errors) {
        state.errors[payload.question.id!] = checkAnswer(payload.question, payload.answer)
      }
    },
    comment: (state, { payload }: PayloadAction<{ answer: AnswerEntity; question: SurveyQuestionEntity }>) => {
      const i = state.answers.findIndex((item) => item.question_code === payload.answer.question_code)
      if (i !== -1) {
        state.answers[i] = { ...state.answers[i], comment: payload.answer.comment }
      } else {
        state.answers.push({ ...payload.answer, answer: '', answers: [] })
      }
    }
  }
})

export const { actions: surveyFeedbackActions, reducer: surveyFeedbackReducer } = slice
