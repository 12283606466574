import { AttachmentEntity } from 'models/attachment'
import { AnswerEntity } from './answer'
import { EntityBase } from './common'

export interface SurveyQuestionEntity extends EntityBase {
  name: string
  description: string
  visible: boolean
  image: AttachmentEntity
  placeholder?: string
  code: string
  type: QuestionType
  options: QuestionOptionEntity[]
  answer_format?: AnswerFormat
  answers?: AnswerEntity[]
  required: boolean
  allow_comment: boolean
  allow_comment_label?: string

  special_field: SpecialField
}

export type AnswerFormat = 'email' | 'phone_number' | 'none'
export const AnswerFormats: AnswerFormat[] = ['email', 'phone_number', 'none']
export const AnswerFormatLabel: Record<AnswerFormat, string> = {
  email: 'Địa chỉ email',
  phone_number: 'Số điện thoại',
  none: 'Không có định dạng'
}

export type QuestionOptionType = 'index' | 'radio' | 'checkbox'
export interface QuestionOptionEntity {
  code: string
  label: string
  mapping_value?: string | number
  type: QuestionOptionType
}
export type QuestionType =
  | 'short_answer'
  | 'paragraph'
  | 'multiple_choice'
  | 'checkboxes'
  | 'dropdown'
  | 'multiple_choice_grid'
  | 'checkboxes_grid'
  | 'date'
  | 'nps'

export const QuestionTypes: QuestionType[] = [
  'short_answer',
  'paragraph',
  'multiple_choice',
  'checkboxes',
  'dropdown',
  'multiple_choice_grid',
  'checkboxes_grid',
  'date',
  'nps'
]
export const QuestionTypeName: Record<QuestionType, string> = {
  checkboxes: 'Hộp kiểm',
  dropdown: 'Menu thả xuống',
  multiple_choice: 'Trắc nghiệm',
  paragraph: 'Trả lời dài',
  short_answer: 'Trả lời ngắn',
  multiple_choice_grid: 'Lưới trắc nghiệm',
  checkboxes_grid: 'Lưới hộp kiểm',
  date: 'Ngày',
  nps: 'Đánh giá dịch vụ (NPS)'
}

export const QuestionTypeDes: Record<QuestionType, string> = {
  checkboxes: 'Khách hàng có thể chọn nhiều tuỳ chọn.',
  dropdown: 'Khách hàng chỉ có thể chọn một tuỳ chọn, dùng với trên 8 tuỳ chọn.',
  multiple_choice: 'Khách hàng chỉ chọn một tuỳ chọn.',
  paragraph: 'Dùng để hỏi về ý kiến, nhận xét về dịch vụ, khách có thể nhập nhiều phản hồi ở đây.',
  short_answer: 'Dùng để hỏi về tên, sđt,...',
  multiple_choice_grid: 'Có nhiều câu hỏi nhỏ, mỗi câu hỏi khách chỉ có thể chọn một tuỳ chọn.',
  checkboxes_grid: 'Có nhiều câu hỏi nhỏ, mỗi câu hỏi khách chỉ có thể chọn nhiều tuỳ chọn.',
  date: 'Dùng để hỏi về ngày tháng, như sinh nhật.',
  nps: 'Dùng để khảo sát mức độ hài lòng của khách hàng.'
}
export type SpecialField = 'customer_name' | 'customer_email' | 'customer_phone'
export const SpecialFields: SpecialField[] = ['customer_email', 'customer_name', 'customer_phone']
export const SpecialFieldLabel: Record<SpecialField, string> = {
  customer_name: 'Tên',
  customer_email: 'Email',
  customer_phone: 'Số điện thoại'
}
