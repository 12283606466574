import { CampaignEntity, EnumBrand } from 'models/campaign'
import { EntityBase } from 'models/entity'
import { UserEntity } from 'models/user_portal'
import { SurveyQuestionEntity } from './question'
import { AttachmentEntity } from 'models/attachment'

export enum EnumSurveyStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  COMPLETE = 'complete'
}
export interface SurveyThemeConfig {
  main_color?: string
  background_color?: string
}

export interface SurveyEntity extends EntityBase {
  name: string
  customer_display_name: string
  url_paths: string[]
  theme_config?: SurveyThemeConfig
  status: EnumSurveyStatus
  code: string
  description: string
  created_by: UserEntity
  brand: EnumBrand
  feedback_count: number
  slug: string
  limit_feedback_per_customer: number
  thanks_template: string
  html_paragraphs: SurveyHtmlParagraph[]
  image_slides: SurveyImageSlideEntity[]
  questions: SurveyQuestionEntity[]
  arrangement: SurveyPartItem[]
  display_conditions: DisplayCondition[]
  code_files?: AttachmentEntity[]
  total_code: number
  assigned_code: number
  campaigns: CampaignEntity[]
  created_at: string
}
export interface SurveyHtmlParagraph {
  code: string
  name: string
  content: string
}
export interface SurveyImageSlideEntity {
  name: string
  images: AttachmentEntity[]
  code: string
}
export interface SurveyPartItem {
  code: string
  type: SurveyPartType
  display_condition_code?: string
}

export type DisplayConditionType = 'answer_a_question' | 'select_a_option'
export const DisplayConditionTypeLabel: Record<DisplayConditionType, string> = { answer_a_question: 'Trả lời câu hỏi', select_a_option: 'Chọn một tuỳ chọn' }

export const DisplayConditionTypeDesc: Record<DisplayConditionType, string> = {
  answer_a_question: 'Khách trả lời câu hỏi.',
  select_a_option: 'Khách chọn một tuỳ chọn của câu hỏi.'
}
export interface DisplayCondition {
  title: string
  code: string
  type: DisplayConditionType
  question_code: string
  option_code?: string
  apply_part_codes: string[]
}
export type SurveyPartType = 'question' | 'image_slide' | 'html_paragraph'

export const SurveyPartTypeLabel: Record<SurveyPartType, string> = { html_paragraph: 'Đoạn', image_slide: 'Ảnh/slide ảnh', question: 'Câu hỏi' }

export const SurveyStatusLabel: Record<EnumSurveyStatus, string> = { draft: 'Bản nháp', active: 'Hoạt động', complete: 'Kết thúc' }

export const SurveyStatusDesc: Record<EnumSurveyStatus, string> = {
  draft: 'Soạn thảo nội dung, câu hỏi.',
  active: 'Chờ khách hàng phản hồi.',
  complete: 'Khách không thể gửi phản hồi nữa.'
}
