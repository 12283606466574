import { PlatformType } from 'hooks'
import { Parser, ProcessNodeDefinitions } from 'html-to-react'
import { ThankFormFieldMapping, ThanksFormData } from 'models'
import React from 'react'
import Barcode from 'react-barcode'

interface Props {
  data: string
}
const customElements: any = {
  barcode: Barcode
}

// function CustomBarcode({ value }: { value?: string }) {
//   console.log('value: ', value)
//   return <Barcode value={value ?? '1234567'} />
// }

const htmlParser: any = new Parser(React)
const processNodeDefinitions: any = new ProcessNodeDefinitions(React)
function isValidNode() {
  return true
}

const processingInstructions = [
  // Create instruction for custom elements
  {
    shouldProcessNode: (node: any) => {
      // Process the node if it matches a custom element
      return node.name && customElements[node.name]
    },
    processNode: (node: any) => {
      const OrignalElement = customElements[node.name]
      const { attribs } = node

      const convertedAttribs = Object.keys(attribs).reduce(
        (acc, key) => {
          let value = attribs[key]

          // Convert numeric strings to numbers
          if (!isNaN(value) && value.trim() !== '') {
            acc[key] = Number(value)
          }
          // Convert boolean-like strings to booleans
          else if (value.toLowerCase() === 'true') {
            acc[key] = true
          } else if (value.toLowerCase() === 'false') {
            acc[key] = false
          }
          // Keep as string if no conversion applies
          else {
            acc[key] = value
          }

          return acc
        },
        {} as Record<string, any>
      )
      return <OrignalElement {...convertedAttribs} />
    }
  },
  // Default processing
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode
  }
]
export function HtmlReact({ data }: Props) {
  return htmlParser.parseWithInstructions(data, isValidNode, processingInstructions)
}

export function embedThanksParams(content: string, params: ThanksFormData, platform: PlatformType) {
  if (params.code) {
    content = content.replace(
      ThankFormFieldMapping.code,
      platform === 'mobile'
        ? `<Barcode value=${params.code}  fontSize=13 format="CODE128" renderer="svg" height=50 width=1.2 />`
        : `<Barcode  value=${params.code}   fontSize=16 format="CODE128" renderer="svg" height=80 width=2 />`
    )
  }
  return content
}
