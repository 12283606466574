import { Stack, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from 'utils'

interface Props {
  label: string
  caption?: string
  require?: boolean
}
export function InputLabel({ label, require, caption }: Props) {
  return (
    <Stack sx={{ marginBottom: '4px' }}>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography sx={{ fontFamily: 'Be Vietnam Pro, sans-serif', fontStyle: 'normal', fontWeight: 'normal', fontSize: '13px', color: COLORS.DarkCharcoal }}>
          {label}
        </Typography>
        {require && <Typography sx={{ color: '#FF3141', ml: '8px' }}>*</Typography>}
      </Stack>
      {caption && (
        <Typography
          sx={{
            fontFamily: 'Be Vietnam Pro, sans-serif',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '11px',
            color: COLORS.nickel,
            marginLeft: '4px',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {caption}
        </Typography>
      )}
    </Stack>
  )
}
