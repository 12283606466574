import { Box } from '@mui/material'
import { GlobalPopup, globalPopupRef } from 'components/GlobalPopup'
import React from 'react'
import { ToastContainer } from 'react-toastify'

export interface Props {
  background_color?: string
  children?: React.JSX.Element | React.JSX.Element[] | any[]
}
export function MainContent({ background_color, children }: Props) {
  return (
    <Box sx={{ backgroundColor: background_color ?? '#F7F7F7' }}>
      <GlobalPopup ref={globalPopupRef} />
      <Box sx={{ padding: '16px' }}>{children}</Box>
      <ToastContainer />
    </Box>
  )
}
