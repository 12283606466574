import { showToast, Table } from 'components'
import { useTableConfig, useTableData } from 'components/Table/hook'
import { env } from 'config'
import dayjs from 'dayjs'
import { useSelector } from 'hooks'
import { useCustomerCare } from 'hooks/customer_care'
import {
  CareStatusCaption,
  CareStatusLabel,
  CustomerCareSortFields,
  CustomerCareSortLabel,
  CustomerGenderLabel,
  EnumAction,
  EnumBrand,
  EnumCareStatus,
  EnumCareType,
  EnumCustomerCareSortField,
  EnumFormatRank,
  EnumModule,
  EnumOrgUnitType,
  EnumTable,
  FormatRankLabel,
  InputConfig,
  OrgUnitEntity,
  StoreEntity,
  TableConfig,
  UserEntity
} from 'models'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { careActions, hasPermission, tableActions } from 'store'
import { COLORS, createSlug, formatDateEn } from 'utils'
import { FMCareColumns, TKLCareColumns } from './config'
import { CareDetail } from './detail'

export function CareList({ brand, store_units }: { brand: EnumBrand; store_units: OrgUnitEntity[] }) {
  const { campaign } = useSelector((x) => x.campaign)

  var table_code = campaign == null ? EnumTable.BRAND_CARE_LIST : EnumTable.CAMPAIGN_CARE_LIST
  const { table } = useTableData(table_code)
  const { user } = useSelector((x) => x.profile)
  const dispatch = useDispatch()

  const FilterInput: InputConfig<any>[] = [
    {
      label: 'Hạng Format',
      type: 'dropdown',
      data: [EnumFormatRank.MEMBER, EnumFormatRank.NEAR_VIP, EnumFormatRank.SILVER, EnumFormatRank.GOLD, EnumFormatRank.DIAMOND, EnumFormatRank.ROYAL],
      name: 'fm_rank',
      getLabel: (a: EnumFormatRank) => FormatRankLabel[a]
    },
    {
      label: 'Khách đã làm khảo sát',
      type: 'dropdown',
      data: [true, false],
      name: 'has_survey_feedback',
      getLabel: (a: any) => (a ? 'Đã làm - ✅' : 'Chưa làm - ❌')
    },
    {
      label: 'Khách đã có code/voucher',
      type: 'dropdown',
      data: [true, false],
      name: 'has_promo',
      getLabel: (a: any) => (a ? 'Đã có - ✅' : 'Chưa có - ❌')
    },
    {
      label: 'Khách đã dùng code/voucher',
      type: 'dropdown',
      data: [true, false],
      name: 'used_promo',
      getLabel: (a: any) => (a ? 'Đã dùng một vài ưu đãi - ✅' : 'Chưa dùng - ❌')
    },
    {
      label: 'Trạng thái chăm sóc',
      type: 'dropdown',
      data: [
        EnumCareStatus.FIND_UNIT_CARER,
        EnumCareStatus.FIND_CARER,
        EnumCareStatus.NOT_CONTACT_YET,
        EnumCareStatus.UNABLE_CONTACT,
        EnumCareStatus.CONTACT_DONE
      ],
      name: 'status',
      getLabel: (a: EnumCareStatus) => CareStatusLabel[a],
      getCaption: (a: EnumCareStatus) => CareStatusCaption[a]
    },
    {
      label: 'Sinh nhật từ (bỏ qua năm)',
      type: 'input_date',
      name: 'birthday__from',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM') : '')
    },
    {
      label: 'Sinh nhật đến (bỏ qua năm)',
      type: 'input_date',
      name: 'birthday__to',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM') : '')
    },
    {
      label: 'Hạn điểm từ',
      type: 'input_date',
      name: 'score_expire__from',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM/YYYY') : '')
    },
    {
      label: 'Hạn điểm đến',
      type: 'input_date',
      name: 'score_expire__to',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM/YYYY') : '')
    }
  ]

  if (hasPermission(EnumModule.CAMPAIGN, EnumAction.CAMPAIGN_VIEW_STORE_STATISTIC)) {
    FilterInput.unshift({
      label: 'Cửa hàng',
      type: 'dropdown',
      data: store_units ?? [],
      filterFunc: (u: OrgUnitEntity, searchKey: string) => createSlug([u.name]).includes(createSlug([searchKey])),
      name: 'unit_carer',
      getLabel: (a: OrgUnitEntity) => a.name?.replace(/^tokyolife /i, 'T ')?.replace(/^format /i, 'F '),
      getCaption: (a: OrgUnitEntity) => a.leader?.name ?? ''
    })
    FilterInput.unshift({
      label: 'Nhân viên chăm sóc',
      type: 'dropdown',
      url: `${env.api.customer_care}/users`,
      name: 'carer',
      getLabel: (a: UserEntity) => `${a.name}`,
      getCaption: (a: UserEntity) => `${a.company}`
    })
  }

  console.log('Brand is Format: ', brand, brand === EnumBrand.Format)

  const Config: TableConfig<any> = {
    code: table_code,
    search: {
      placeholder: 'Tìm theo tên khách, số điện thoại'
    },
    row_action_first: true,
    filters: {
      inputs: FilterInput
    },
    sort_by_input: {
      placeholder: 'Sắp xếp',
      data: [undefined, ...CustomerCareSortFields],
      getLabel: (a: EnumCustomerCareSortField) => (a == null ? 'Không sắp xếp' : CustomerCareSortLabel[a])
    },
    columns: brand === EnumBrand.Format ? FMCareColumns : TKLCareColumns,
    url: `${env.api.customer_care}/customer-cares`,
    row_actions: [
      {
        label: 'Xem thông tin',
        action_type: 'view'
      }
    ]
  }
  const { config } = useTableConfig({ config: Config })

  const campaign_id = campaign?.id

  const enable_view_store = hasPermission(EnumModule.CAMPAIGN, EnumAction.CAMPAIGN_VIEW_STORE_STATISTIC)
  useEffect(() => {
    var params: any = {
      campaign: campaign_id ? campaign_id : null,
      brand: !campaign_id ? brand : campaign.type,
      care_type: campaign_id ? EnumCareType.CAMPAIGN : EnumCareType.BRAND
    }
    if (!enable_view_store) {
      params.carer = user
    }
    dispatch(
      tableActions.changeFilter({
        name: table_code,
        data: params
      })
    )
  }, [campaign_id, dispatch, brand, enable_view_store, user?.id, table_code])

  useEffect(() => {
    dispatch(
      tableActions.changeFilter({
        name: table_code,
        data: {
          sort_field: table?.sort_field,
          sort_asc: table?.sort_asc
        }
      })
    )
  }, [table?.sort_field, table?.sort_asc, dispatch, table_code])

  const { getDetail } = useCustomerCare()

  const [open, setOpen] = useState<boolean>(false)
  const { care } = useSelector((x) => x.care)
  useEffect(() => {
    setOpen(care != undefined)
  }, [care])

  return (
    <>
      <CareDetail
        open={open}
        onClose={() => {
          dispatch(careActions.setCustomerCare(null))
        }}
      />
      <Table
        code={Config.code}
        onItemAction={(action_type, item) => {
          if (action_type === 'view') {
            showToast({ content: `Đang lấy thông tin khách hàng...`, type: 'info' })
            getDetail(item.id)
          }
        }}
      />
    </>
  )
}
