export interface ModuleEntity {
  module: EnumModule
  label: string
}

export enum EnumModule {
  GROUP_PERMISSION = 'group_permission',
  CAMPAIGN = 'campaign',
  BRAND_CARE = 'brand_care',
  PORTAL_DATA = 'portal_data',
  SURVEY = 'survey'
}
export const ALL_MODULES: ModuleEntity[] = [
  {
    module: EnumModule.CAMPAIGN,
    label: 'Đợt chăm sóc'
  },
  {
    module: EnumModule.CAMPAIGN,
    label: 'Khách hàng'
  },
  {
    module: EnumModule.PORTAL_DATA,
    label: 'Thông tin chung'
  },
  {
    module: EnumModule.SURVEY,
    label: 'Khảo sát khách hàng'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    label: 'Phân quyền'
  }
]
