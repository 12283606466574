import { Stack, Typography } from '@mui/material'
import { CustomerCareEntity, EnumPromoType } from 'models'
import React from 'react'
import { COLORS, formatDateEn, formatDatetimeEn } from 'utils'
export function Promos({ data }: { data: CustomerCareEntity }) {
  const { promos, used_promos } = data
  return (
    <Stack sx={{ display: 'flex', flex: 1 }}>
      <Typography sx={{ fontSize: '13px', color: COLORS.DarkCharcoal, fontWeight: '500', textAlign: 'center', marginTop: '8px' }}>
        {!promos || promos?.length === 0 ? 'Khách không có code/voucher nào trong đợt chăm sóc nào' : 'Các code/voucher'}
      </Typography>
      {promos?.map((promo_code, index) => {
        var used_promo = used_promos?.find((u) => u.value === promo_code.value)
        console.log('Used promo: ', used_promo?.type, used_promo?.value)

        const color = used_promo ? COLORS.DarkSpringGreen : COLORS.DarkCharcoal
        return (
          <Stack direction={'column'} sx={{ my: 1 }}>
            <Stack direction={'row'}>
              <Typography
                style={{
                  fontSize: '13px',
                  fontWeight: used_promo ? '500' : '400',
                  color: color,
                  flex: 1,
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {promo_code.import_name}
              </Typography>
              {used_promo ? <Typography style={{ fontSize: '11px', color: color }}>{formatDatetimeEn(used_promo.used_at)}</Typography> : <div />}
            </Stack>
            <Typography style={{ fontSize: '12px', color: color, fontWeight: used_promo ? '700' : '400' }}>
              {used_promo
                ? (promo_code.type === EnumPromoType.CODE
                    ? 'Khách đã dùng code này'
                    : ` Khách đã dùng voucher: ${used_promo?.used_voucher_price?.toLocaleString() ?? 0} / ${used_promo.total_voucher_price?.toLocaleString() ?? 0}`) +
                  ` - Cửa hàng: "${used_promo?.used_store?.name ?? ''}".`
                : `Khách chưa dùng ${promo_code.type} này.`}
            </Typography>
          </Stack>
        )
      })}
    </Stack>
  )
}
